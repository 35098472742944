.main_about_eth{
    padding: 4rem 20px;
}


.main_about_heading{
    color: rgb(299, 299, 299);
    font-weight: 700;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 3rem;
    text-align: center;

}


.full_ab_box{
    border:2px solid white;
    border-radius: 25px;
    padding: 20px ;
    display: flex;
    align-items: center;
}

.full_ab_box p{
    color: rgb(299, 299, 299);
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    font-family: 'Space Grotesk', sans-serif;
    margin-bottom: 0px;

}

.about_main_box{
    background-color: rgb(23 23 23);
    border-left: 3px solid rgb(54 ,210 ,205);
    padding: 30px;
    /* height: 250px; */
}


.right_bor{
    border-right: 3px solid rgb(54 ,210 ,205) !important;
    border-left: none !important;

}

.both_side{
    border-right: 3px solid rgb(54 ,210 ,205) !important;
}

.about_main_box h4{
    color: rgb(299, 299, 299);
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    font-family: 'Space Grotesk', sans-serif;
    margin-bottom: 2rem;

    

}


.about_main_box p {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    font-family: 'Space Grotesk', sans-serif;
    color: rgb(299, 299, 299);
}


.stake_btn{
    background-color:rgb(43 168 164);
    color: black;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    justify-content: center;
    border: none;
    font-family: 'Space Grotesk', sans-serif;
padding:0.75rem 2rem ;

}



@media only screen and (max-width:600px){
    .main_about_heading{
        font-size: 1.8rem;
    }
}