.main_footer{
    background-color: rgb(23 23 23);
    padding: 2rem 10px 20px 10px;
}

.footer_links{
    color: rgb(163 163 163);
    text-decoration: none;
    line-height: 1.5rem;
    font-family: 'Space Grotesk', sans-serif;
    /* padding: 1rem 8px; */

}

.footer_links:hover{
    color: rgb(163 163 163) !important;

}

.footer_links li {
    padding: 5px;
    list-style-type: none;

}

.lower{
    border-top: 1px solid rgb(163 163 163) ;
    padding: 20px 10px;
}


.lower p {
    color: rgb(163 163 163) !important;

}

.logo_adjsutment{
    position:relative;
    left: -1rem;
    width: 100%;
}


.heading_footer{
    font-size: 3rem;
    color: #fff !important;
    font-weight: 700 !important;
}

@media only screen and (max-width:600px){
    .logo_adjsutment{
        /* position: static !important; */
        width: 100% !important;
        left: 0 !important;
    }
}