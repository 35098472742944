.main_landing_page {
  padding: 5rem 10px;
}

.landing_content_right h2 {
  font-size: 3rem;
  line-height: 1;
  color: rgb(229 229 229);
  font-weight: 700;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 2rem;
}


.outline_eth {
  color: #687E96;
  -webkit-text-fill-color: transparent;
  /* -webkit-text-stroke-color: #F0B90B; */
  -webkit-text-stroke: 1px;
}

.main_staking_div {
  display: flex;
  justify-content: end;
}

.left_box_main {
  padding: 20px 30px;
  border: 2px solid #687E96;
  border-radius: 10px;
  width: 85%;
  background-color: rgb(23 23 23/1);
}


.content_box h4 {
  color: rgb(229 229 229);
  text-align: center;
  font-weight: 500;
  font-family: 'Space Grotesk', sans-serif;
  text-transform: uppercase;



}


.counter {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 60px;
  padding: 8px 5px;
  background-color: white !important;
  border-radius: 15px;
}

.time {
  color: black;
  font-weight: 700;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 18px;
  margin-bottom: 0px;

}


.day_time {
  color: black;
  font-weight: 500;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.presale {
  color: white;
  font-weight: 500;
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 18px;
  margin-top: 1rem;

}

.conect_wallte {
  background-color: #687E96;
  padding: 10px 20px;
  color: black;
  width: 100%;
  border: none;
  font-family: 'Space Grotesk', sans-serif;
  display: flex;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
}

.eth_buy {
  background-color: transparent;
  border: 2px solid #687E96;
  border-radius: 10px;

  padding: 10px 20px;
  color: white;
  width: 100%;

  font-family: 'Space Grotesk', sans-serif;
  display: flex;
  justify-content: center;
  font-weight: 700;
}


.main_progress {
  position: relative;
  background-color: rgb(229 229 229);
  height: 20px !important;
}


.inner_clr {
  position: absolute;
  background-color: #687E96;
  height: 100%;
  width: 20%;
}

.until {
  text-align: center;
  position: absolute;
  left: 40%;
  top: -2px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 18px;


}


.one_btc {
  text-align: center;
  position: relative;
  color: rgb(229 229 229);
  font-size: 18px;
  font-weight: 600;
}

.one_btc::before {
  content: "";
  position: absolute;
  width: 30%;
  left: 0;
  background-color: rgb(229 229 229);
  height: 2px;
  top: 13px
}

.one_btc::after {
  content: "";
  position: absolute;
  width: 30%;
  right: 0;
  background-color: rgb(229 229 229);
  height: 2px;
  top: 13px
}


.eth_Su {
  background-color: rgba(0, 0, 0, 0.125);
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px 5px;
  border-style: solid;
  border-color: white;
  gap: 10px;
  width: 100%;
  cursor: pointer;

}

.eth_Su p {
  color: rgb(229 229 229);
  font-size: 20px;
  font-weight: 600;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 0px;
}


.heading_in {
  font-size: 14px;
  font-weight: 500;
  color: rgb(229 229 229);
  font-family: 'Space Grotesk', sans-serif;


}

.max_btn {
  color: #687E96;
  font-weight: 700;
  font-size: 18px;
  background-color: transparent;
  border: none;
  outline: none !important;
}


.main_input_box {
  background-color: rgb(229 229 229) !important;
  padding: 2px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}


.input_box_pre {
  background-color: transparent;
  border: none;
  padding: 10px;
  width: 80%;
  color: black;
}

.input_box_pre:focus {
  outline: none;
  border: none;
}

.eth_Su .logo_input img {
  width: 80%;
}

.main_input_box img {
  width: 18%;
}

@media only screen and (max-width:600px) {
  .landing_content_right h2 {
    font-size: 1.8rem !important;
  }

  .eth_Su img {
    width: 27px !important;
  }

  .one_btc::before {
    width: 20% !important;
  }

  .one_btc::after {
    width: 20% !important;
  }

  .eth_Su {
    width: 5rem;
    gap: 2px !important;
  }

  .eth_Su p {
    font-size: 16px;
  }
}




input::placeholder {

  color: black;
}

@media screen and (max-width:800px) {
  .main_staking_div {
    display: flex;
    justify-content: center;
  }
  .left_box_main{
    width: 100%;
  }
  .eth_Su .logo_input img {
    width: 100%;
  }

}