

.gap-3 {
    gap: 1rem!important;
}
.justify-content-around {
    justify-content: space-around!important;
}
.d-flex {
    display: flex!important;
}
.walletBtn {
    border: 2px solid #009393 !important;
    border-radius: 15px;
    width: 196px;
    height: 205px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.cursor-pointer {
    cursor: pointer!important;
}
.mb-3 {
    margin-bottom: 1rem!important;
}
img, svg {
    vertical-align: middle;
}
.bold-text {
    font-family: 'Mina', sans-serif !important;

}
.text-uppercase {
    text-transform: uppercase!important;
}
.fs-5 {
    font-size: 1.125rem!important;
}
.d-block {
    display: block!important;
}
 .header {
    background: #009393;
    color: #fff;
    /* padding: 20px; */
    font-weight: bold !important;
    display: flex;
    justify-content: center !important;
}
.text-center {
    text-align: center!important;
}
.headerdiv{
    text-align: center !important;
    font-family: 'Mina', sans-serif !important;
    font-weight: 700 !important;

    /* border: 1px solid red; */
}
.walletBtn span{
    font-weight: 700 !important;
}