.main_comp {
    padding: 4rem 10px;
}

.main_cmp_head{
    color: rgb(229, 229, 229);
    font-weight: 700;
    font-size: 36;
    font-family: 'Space Grotesk', sans-serif;
    text-align: center;
    margin-bottom: 1rem;

}

.cmp_para{
    color: rgb(229, 229, 229);
    font-weight: 400;
    font-size: 18;
    font-family: 'Space Grotesk', sans-serif;
    text-align: center; 
}


tr {
    border: 1px solid #687E96;
    color: rgb(229, 229, 229) !important;
    font-family: 'Space Grotesk', sans-serif;


}

th{
    border-left: 1px solid #687E96;
}


td {
    border-left: 1px solid #687E96;
}

.samll_text{
    color: rgb(229, 229, 229);
    font-family: 'Space Grotesk', sans-serif;


}
.bg_clr_tab{
    background-color: rgba(43 ,168 ,164,0.7) !important; 
}
.lgt_clr_bg{
    background-color: rgba(43 ,168 ,164,0.3) !important; 

}

