.main_white_paper{
    padding: 4rem 10px;
}

.white_bo{
    border: 1px   rgba(64,64,64,.8);
    background-color: rgb(23, 23, 23);
    padding: 20px ;
}

.white_bo h2{
color: rgb(229, 229, 229);
font-weight: 700;
font-size: 40px;
font-family: 'Space Grotesk', sans-serif;
text-align: center;
}

.white_bo h1{
    color: rgb(229, 229, 229);
    font-weight: 600;
    font-size: 36px;
    font-family: 'Space Grotesk', sans-serif;
    text-align: center; 
}

.white_bo p{
    color: rgb(229, 229, 229);
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    font-family: 'Space Grotesk', sans-serif;
}