.main_buy_eth{
    padding: 4rem 10px;
}



.main_heading_buy{
    color: rgb(229, 229, 229);
    font-weight: 700;
    font-size: 3rem;
    text-align: center;
    font-family: 'Space Grotesk', sans-serif;

}


.buy_box_eth{

}


.buy_main_box_here{
   border: 2px solid #687E96;
   padding: 25px 34px;
   border-radius:25px ;
   height: 350px;
   background-color: #687E96;

}

.buy_main_box_here h4 {
    color: rgb(229, 229, 229);
    font-size: 22px;
    font-weight: 700;
    font-family: 'Space Grotesk', sans-serif;
    text-align: center;

}


.buy_ul{
    color: rgb(229, 229, 229);
    font-size: 18px;
    font-weight: 400;
    font-family: 'Space Grotesk', sans-serif;
    text-align: left;
    list-style-type: disc;
}

.site_main_buttn{
    background-color: #687E96;
    color: black;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    justify-content: center;
    border: none;
    padding: 0.75rem 3rem; 
    text-transform: uppercase;
}


@media only screen and (max-width:600px){
    .main_heading_buy {
        font-size: 2rem;
    }
}