.main_road_map{
    padding: 4rem 10px !important;
}

.rd_main_h{
    color: rgb(229, 229, 229);
    font-size: 3rem;
    font-weight: 700;
    font-family: 'Space Grotesk', sans-serif;
    margin-bottom: 1rem;
    text-transform: uppercase;
    text-align: center;

}

.para_rd{
    color: rgb(229, 229, 229);
    font-size: 18;
    font-weight: 400;
    font-family: 'Space Grotesk', sans-serif;
 
    text-align: center;
}


.road_map_box{
    border-left: 2px solid #687E96;
    border-right: 2px solid #687E96;
    padding: 20px  ;
    position: relative;
    background-color: rgb(23 23 23);
    height: 330px;
}

.rd_number{
    color: rgb(38 38 38);
    top: 0;
    right: 10px;
    position:absolute;
    font-size: 2.25rem;
    font-family: 'Space Grotesk', sans-serif;

}

.road_map_box h2 {
    font-size: 24px;
    color: rgb(229, 229, 229);
    font-family: 'Space Grotesk', sans-serif;
font-weight: 600;

}

.clr_main{
    color: #687E96!important;
}

.road_map_box h5 {
    color: rgb(229, 229, 229);
    font-size: 17px;
    font-weight: 600;
    font-family: 'Space Grotesk', sans-serif;
    margin: 1rem 0px;

}

.road_map_box p{
    color: rgb(229, 229, 229);
    font-size: 15px;
    font-family: 'Space Grotesk', sans-serif;

    font-weight: 400;
}