.main_FAQ{
    padding: 4rem 10px;
}

.faq{
    font-size: 42px;
    text-align: center;
    color: rgb(229, 229, 229);
    font-family: 'Space Grotesk', sans-serif;
font-weight: 600;
}




.main_faq_here{
    padding: 40px 10px;
}





   
/* target this according-body when you want to change acc when it is opened */

.accordion-body {
    background-color: transparent !important;
    
}






.accordion-button{
    font-size: 1.125rem;
      padding: 12px 40px 12px 0 !important;
    color: rgb(229, 229, 229) !important;
    font-weight: 700;
    margin: 0;
    cursor: pointer;
    font-family: 'Space Grotesk', sans-serif;

}





.accordion-header{
    /* background-color: transparent !important    ; */
    border: none !important;
    padding: .5rem .5rem .5rem 1.5rem;

}
.accordion-item{
    border: none !important;
    box-shadow:none !important ;
    background-color: transparent !important;
   border-bottom: 2px solid  #687E96!important;
    /* border-radius: 8px !important; */
}



/* ya button ka background aur padding huti hai is ma  */

.accordion-button:not(.collapsed){
    background-color: transparent !important;
    /* background-color: red !important; */
    border: none !important;
    /* box-shadow: none !important; */
    /* border-radius: none !important; */
    color: #687E96!important;
}


/* ya button hai acc ka jb wo band huta tu is ki styles lyta hai wo  */
.accordion-button{
    background-color: transparent !important;
    border: none !important;
    border-radius: none !important;
    /* padding: 1rem 2.5rem 1rem 1.5rem; */
}




/* blue shadow khtm krny k liya jo focus krny py ata hai  button pr  */


.accordion-button:focus {
    border: none !important;
    box-shadow: none !important;
    border-radius: none !important;
}

  /* ya hai acc ka icon change krny k liya aur icon jb font-awsome ka hu  */




@media only screen and (max-width:600px){
    .faq_main_he h2 {
        font-size: 32px !important;
    }
    .accordion-button{
        font-size: 1.125rem !important;
    }

    .faq_heading{
        font-size: 1.75rem !important ;
        margin-top: 1rem !important;
    }
    .faq {
        font-size: 34px !important;
    }
}








.acc_para{
    /* color: hsla(0,0%,100%,.7) !important; */
    color: rgb(229, 229, 229) !important;
    font-family: 'Space Grotesk', sans-serif;

}




.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image:none !important;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}







